require('./bootstrap');
require('slick-carousel');
require('bootstrap-select');
require('bootstrap-select/dist/js/i18n/defaults-sk_SK.min')
require('magnific-popup')
import 'vanilla-cookieconsent'

const slickUpper = $('.slick-upper')
const partnersSlick = $('.partners .slick-items')

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    type: 'POST',
    cache: false
});

$('select').selectpicker();

$(function () {
    if($('#appDetail').length) {
        let galleryHeight = $('.gallery').height()
        let descriptionHeight = $('.description').height()
        if(descriptionHeight < galleryHeight) {
            $('.hr-spacer').css('margin-top', (galleryHeight+80)+'px')
        }
    }
    $("#magicBtn").on("click", function () {
        $("#mesto").toggle(this.checked);
    });
    $('.gallery-item').magnificPopup({
        type: 'image',
        gallery: {
            enabled:true,
            tPrev: 'Predošlí (Ľavá šípka)',
            tNext: 'Ďalší (Práva šípka)',
            tCounter: '%curr% z %total%'
        },
        image: {
            tError: '<a href="%url%">Obrázok</a> nebolo možné načítať.'
        },
    });

    $('.magnific-gallery').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            preload: [1,2],
            gallery: {
                enabled:true,
                tPrev: 'Predošlí (Ľavá šípka)',
                tNext: 'Ďalší (Práva šípka)',
                tCounter: '%curr% z %total%'
            },
            image: {
                tError: '<a href="%url%">Obrázok</a> nebolo možné načítať.'
            },
        });
    });
    let hash = window.location.hash
    hash = hash.replace('#', ".");
    $(hash).trigger('click');
});

$('.copy-realization').on('click touch', function () {
    let realizationHash = $(this).data('realization-hash')
    let URL = window.location.origin + '/realizacie' + realizationHash
    copyRealization($(this), URL)
})
const copyRealization = function(elem, copyText) {
    let textArea = document.createElement("textarea");
    textArea.value = copyText;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        let successful = document.execCommand("copy");
        if (successful && elem) {
            elem.html("Skopírované");
            setTimeout(function () {
                elem.html("Zdieľať");
            }, 1000);
        }
    } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
}

let filterProductsSection = $('#filter-products-section');
if(filterProductsSection.length && $('#appProducts').length) {
    require('./jquery.hash')
    const categoryId = filterProductsSection.data('category-id')
    $('body').on('change', '.filters select', function () {
        filterSelect($(this))
    });
    const getHash = function () {
        let hash = $.hash.getParamsObject()
        if ($.isEmptyObject(hash)) {
            return false
        } else {
            $.each(hash, function (key, value) {
                let trimedKey = key
                if(trimedKey.includes("-")) {
                    trimedKey = trimedKey.substring(0, trimedKey.indexOf('-'));
                }
                if ($.type(value) == 'string') {
                    hash[trimedKey] = decodeURI(value).split(',')
                }
            })
            return hash
        }
    }
    const filterProducts = function (remove, slug = null) {
        if(remove) {
            $.hash.remove('page')
        }
        let hash = getHash()
        let page = '';
        if(window.location.href.includes("?page=")) {
            let url = new URL(window.location.href);
            page = url.searchParams.get("page");
            page = '?page='+page;
            if(remove) {
                let newUrl = window.location.href
                newUrl = newUrl.replace(page, "");
                window.history.pushState({},"", newUrl);
                page = '';
            }
        }
        $.ajax({
            url: '/filter'+page,
            type: 'POST',
            cache: false,
            data: {hash: hash, categoryId: categoryId, slug: slug},
            dataType: 'html',
            success: function (data) {
                if (data != 'false') {
                    $("#filter-products-section").empty().html(data);
                    $('select').selectpicker('refresh');
                    reInitFilter()
                    var links = $('.hash-pagination').find('a');
                    links.each(function(link){
                        var href = $(this).attr('href');
                        $(this).attr('href', href + window.location.hash);
                    });
                } else {
                    console.log('Ajax Error in script')
                }
            },
            error: function (jqXHR, textStatus) {
                console.log('Ajax', 'Error', textStatus)
            }
        })
    }
    const checkEmptyHash = function () {
        if (getHash() === false) {
            history.pushState({}, document.title, location.href.replace('#', ''))
        }
    }
    const filterSelect = function (e) {
            e = $(e)
            let vals = e.val()
            let slug = e.data('hash')

            if(slug.includes("-")) {
                slug = slug.substring(0, slug.indexOf('-'));
            }
            if(!vals.length && slug !== 'vyrobca') {
                let top = $(window).scrollTop()
                $.hash.remove(slug)
                checkEmptyHash()
                $(window).scrollTop(top)
            } else {
                if(!vals.length && slug === 'vyrobca') {
                    $.hash.remove('vyrobca')
                } else {
                    if(slug === 'vyrobca') {
                        let hash = getHash()
                        $.each(hash, function (key,j) {
                            $.hash.remove(key)
                        })
                        $.hash.remove()
                    }
                    $.hash.set(slug, vals)
                }
            }
        filterProducts(true, slug)
    }

    const reInitFilter = function () {
        let hash = getHash()
        $('select').each(function () {
            let e = $(this)
            let hashValues = hash[e.data('hash')]
            if (hashValues) {
                e.attr('checked')
                e.val(hashValues)
            }
            e.selectpicker('refresh')
        })
    }

    $(function () {
        filterProducts(false)

        if (getHash() !== false) {
            reInitFilter()
        }
        $('body').on('click touch', '#more-products', function () {
            let moreProducts = $(this);
            if(moreProducts.hasClass('not-last')) {
                let nextPage = moreProducts.data('next-page')
                let hash = getHash()
                $.ajax({
                    url: '/next-products?page='+nextPage+window.location.hash,
                    type: 'POST',
                    cache: false,
                    data: {hash: hash, categoryId: categoryId},
                    dataType: 'html',
                    success: function (data) {
                        if (data != 'false') {
                            $(data).insertAfter($('.product').last());
                            if(moreProducts.data('last-page') == nextPage) {
                                moreProducts.addClass('d-none')
                            } else {
                                moreProducts.data('next-page', nextPage)
                            }
                            moreProducts.data('next-page', nextPage+1)
                        } else {
                            console.log('Ajax Error in script')
                        }
                    },
                    error: function (jqXHR, textStatus) {
                        console.log('Ajax', 'Error', textStatus)
                    }
                })
            }
        });
    })
}

$(function () {
    // if (slickUpper.length) {
    //     slickUpper.slick({
    //         speed: 800,
    //         autoplay: true,
    //         autoplaySpeed: 3000,
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         accessibility: false,
    //         dots: false,
    //         infinite: true,
    //         arrows: false,
    //         adaptiveHeight: false,
    //         fade: true
    //     });
    // }
    if (partnersSlick.length) {
        partnersSlick.slick({
            speed: 600,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 5,
            slidesToScroll: 1,
            accessibility: false,
            dots: false,
            infinite: true,
            prevArrow: "<div class='d-flex align-items-center'><i class=\"fas fa-chevron-left\"></i></div>",
            nextArrow: "<div class='d-flex align-items-center'><i class=\"fas fa-chevron-right\"></i></div>",
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
})

let carouselUpperInner = $('#carouselUpper .carousel-inner')
let carouselUpper = $('#carouselUpper')
if(carouselUpper) {
    $( document ).ready(function() {
        setTimeout(function () {
            $('.carousel-item.active .carousel-caption').removeClass('hidden')
        }, 300)
    })
}
carouselUpper.on('slid.bs.carousel', function () {
    setTimeout(function () {
        $('.carousel-item.active .carousel-caption').removeClass('hidden')
    }, 200)
})
carouselUpper.on('slide.bs.carousel', function () {
    setTimeout(function () {
        $('.carousel-item.active .carousel-caption').addClass('hidden')
    }, 200)
})

if ($(window).width() < 768) {
    carouselUpperInner.on('touch tap click', function () {
        let link = $(this).find('.carousel-item.active a')
        if(link.length) {
            window.location.href = link.attr('href')
            return true
        }
        return false
    })
}

$('#zp-section .zp-modal').on('click touch tap', function () {
    $('input[name="product"]').val($(this).parent().find('h3').html())
})

$(window).scroll(function() {
    if ($(this).scrollTop() > 800) { //use `this`, not `document`
        $('.arrow.animated').css({
            'display': 'none'
        });
    }
});


const cc = initCookieConsent()
cc.run({
    current_lang: 'sk',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false
    level: [
        "necessary"
    ],

    // mode: 'opt-in',                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    remove_cookie_tables: true,             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {
        if(cc.allowedCategory('necessary')){
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
                event:"CookieConsent",
                consentType:"necessary"
            });
        }


        if(cc.allowedCategory('analytics')){
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
                event:"CookieConsent",
                consentType:"analytics"
            });
            if(typeof gtag !== 'undefined') {
                gtag('consent', 'update', {
                    ad_storage: 'granted'
                });
            }
        }
        if(cc.allowedCategory('ad_storage')){
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
                event:"CookieConsent",
                consentType:"ad_storage"
            });
            if(typeof gtag !== 'undefined') {
                gtag('consent', 'update', {
                    ad_storage: 'granted'
                });
            }
        }
    },

    onChange: function (cookie, changed_preferences) {
        if(cc.allowedCategory('analytics')){
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
                event:"CookieConsent",
                consentType:"analytics"
            });

            if(typeof gtag !== 'undefined') {
                gtag('consent', 'update', {
                    analytics_storage: 'granted',
                });
            }
        } else {
            if(typeof gtag !== 'undefined') {
                gtag('consent', 'update', {
                    analytics_storage: 'denied',
                });
            }
        }
        if(cc.allowedCategory('ad_storage')){
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
                event:"CookieConsent",
                consentType:"ad_storage"
            });

            if(typeof gtag !== 'undefined') {
                gtag('consent', 'update', {
                    ad_storage: 'granted'
                });
            }
        } else {
            if(typeof gtag !== 'undefined') {
                gtag('consent', 'update', {
                    ad_storage: 'denied'
                });
            }
        }
    },

    languages: {
        'sk': {
            consent_modal: {
                title: 'Na vašom súkromí nám záleží\n',
                description: 'Táto webová stránka používa nevyhnutné súbory cookie na zabezpečenie správneho fungovania a s vaším súhlasom, aj na personalizáciu obsahu našich webových stránok.',
                primary_btn: {
                    text: 'Súhlasím',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Nastavenie cookies',
                    role: 'settings'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookies nastavenia',
                save_settings_btn: 'Uložiť nastavenia',
                accept_all_btn: 'Prijať všetko',
                reject_all_btn: 'Odmietnúť všetko',
                close_btn_label: 'Zavrieť',
                cookie_table_headers: [
                    {col1: 'Názov'},
                    {col2: 'Doména'},
                    {col3: 'Expirácia'},
                    {col4: 'Popis'}
                ],
                blocks: [
                    {
                        title: '',
                        description: 'Súbory cookie používame na zabezpečenie základných funkcií stránky a na zlepšenie vášho používateľského zážitku. Svoj súhlas pre každú kategóriu môžete kedykoľvek zmeniť.'
                    }, {
                        title: 'Nevyhnutné cookies',
                        description: 'Tieto cookies sú nevyhnutné pre správne fungovanie webovej stránky. Bez týchto súborov cookies nebude webová stránka fungovať správne',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Analytické cookies',
                        description: 'Tieto cookie využívame na analyzovanie prenosu a návštevnosti na našej webovej stránke – umožňujú nám o. i. sledovať počet návštevníkov webovej stránky a ako návštevníci webovú stránku používajú. Takáto analýza nám umožňuje lepšie nastavenie webovej stránky pre zlepšenie funkčnosti stránky a zlepšenie užívateľskej spokojnosti. Tieto cookie môžu byť použité len s Vaším predchádzajúcim súhlasom.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        }
                    }, {
                        title: 'Marketingové cookies',
                        description: 'Vďaka marketingovým cookies bude pre Vás používanie nášho webu pohodlnejšie a prispôsobené Vašim preferenciám. Rovnako uvidíte viac ponuky, ktoré Vás môžu zaujímať a menej nerelevantnej reklamy.',
                        toggle: {
                            value: 'ad_storage',     // your cookie category
                            enabled: false,
                            readonly: false
                        }
                    }
                ]
            }
        }
    }
});
